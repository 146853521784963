.event-card {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: initial;
  transition: color ease-in-out .25s;

  .card-img-top {
    object-fit: cover;
    height: 200px;
  }
  .event-favorite {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
    border-radius: 33px;

    .material-symbols-outlined {
      transform: translateX(-8px);
    }
  }

  .event-text {
    .material-symbols-outlined {
      font-size: 16px;
    }

    font-size: 13px;
  }
  .event-price {
    min-width: 35px;
    text-align: end;
  }
  .material-symbols-outlined {
    font-size: 20px !important;
  }
}
