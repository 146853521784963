@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/pagination/pagination.scss';

// required bootstrap stuff
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import "../../node_modules/bootstrap/scss/card";

@import "experience-item";
@import "swiper";

#hoping-text {
  padding: 30px;

  h1 {
    line-height: 1.25;
    font-weight: 400;
  }

  .hoping-link {
    min-width: 280px;
  }
}

#experience-highlight {
  background-color: var(--bs-accent);

  #experience-swiper {
    .experience-slide {
      width: 300px;
      height: 320px;
    }

    .swiper-pagination .swiper-pagination-bullet-active {
      background-color: var(--bs-primary);
    }
  }
}

#category-highlight {
  .category-slide {
    width: 300px;
  }
  .category {
    height: 225px;

    .card-img {
      object-fit: cover;
    }

    .card-img-overlay {
      background-color: #4a494961;
      color: white;

      .card-title {
        font-size: 22px;
      }
    }
  }
}

#newsletter {
  background-color: var(--bs-accent);

  #newsletter-form {
    padding: 5px;
    gap: 1rem;
    box-shadow: 4px 4px 12px tint-color(black, 80);

    .input-group {
      width: unset !important;
    }
  }

  #newsletter-illustration {
    box-shadow: 4px 4px 12px tint-color(black, 75);
  }
}

#region-highlight {
  .region-swiper .region-slide {
    width: 250px;
    .card-img-top {
      height: 165px;
      object-fit: cover;
    }
  }
}
