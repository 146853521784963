$primary: rgb(181, 141, 121);
.swiper {
  .swiper-slide.zoomable-slide {
    margin-top: 45px;
    margin-bottom: 45px;
    transition: all .25s ease-out .15s;
    margin-left: 26px;

    &:last-child {
      margin-right: 26px;
    }

    &:hover {
      transform: scale3d(1.15, 1.15, 2);
    }
  }
}

.swiper-control {
  &:not(.control-inline) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &.control-inline {
    display: flex;
  }
  border: none;
  background-color: transparent;
  outline: none;

  &:disabled {
    opacity: 50%;
    background-color: transparent;
  }

  &:focus, &:active, &:hover {
    outline: none;
    border: none;
    background-color: transparent !important;
  }

  &.swiper-control-previous {
    left: -20px;
  }

  &.swiper-control-next {
    left: auto;
    right: -40px;
  }
}